<template>
  <mi-table
    ref="miTable"
    :columns="columns"
    :loading="loading || isWhyExplanationLoading"
    :rows="comparisonResults"
    class="full-height q-px-lg q-pb-md full-width"
    column-sort-order="da"
    loading-label="Loading validation comparison results. Please wait ..."
    no-data-label="No comparison results found"
    row-key="id"
    virtual-scroll-sticky-size-start="40"
    hide-pagination
    sticky-header
    striped
    wrap-cells
  >
    <template #body="{ cols, row }">
      <mi-tr :selected="expandedValidationResultId === row.id">
        <mi-td v-for="col in cols" :key="col.name">
          <div v-if="col.name === 'pp'">
            <div
              v-for="(label, key, index) in planningPeriodLabels"
              :key="key"
              :class="['flex column', index === 0 ? 'q-mb-md' : '']"
            >
              <span class="text-body3 text-weight-bold text-primary">{{ label }}</span>
              <span>
                {{ formatPlanningPeriod({
                  from: row[`${ key }PlanningPeriodRange`].from || '',
                  to: row[`${ key }PlanningPeriodRange`].to || ''
                }) }}
              </span>
            </div>
          </div>
          <div v-if="col.name === 'status'">
            <div
              v-for="status in formattedStatus(row)"
              :key="status.key"
              class="flex items-center status-column"
            >
              <test-case-validation-results-status-item
                :col="status"
                :expanded="expandedValidationResultId === row.id && expandedValidationResultKey === status.key"
                @click:close="expandedValidationResultId = false"
                @click:why="getValidationResultExplanation(
                  { resultId: row.id, resultKey: status.key }
                )"
              >
              </test-case-validation-results-status-item>
            </div>
          </div>
          <template v-else> {{ col.value }} </template>
        </mi-td>
      </mi-tr>
      <mi-tr v-if="expandedValidationResultId === row.id" no-hover>
        <mi-td colspan="100%">
          <validation-explanation :explanation="validationResultExplanation" class="q-py-sm">
          </validation-explanation>
        </mi-td>
      </mi-tr>
    </template>

    <template v-if="!loading && tab===tabs.ONLY_DIFFERENCES.key" #no-data>
      <div class="no-data-message">
        <p class="no-data-message__text">It looks like nothing has changed!</p>
        <p class="no-data-message__sub-text">All status are still the same for the validations compared.</p>
      </div>
    </template>
  </mi-table>
</template>

<script>
  import { ref } from 'vue'
  import { validationsComparisonResultsColumns } from '@/components/validations/utils'
  import { removePrefixFromPlanningPeriod } from '@/utils/planningPeriod'
  import {
    VALIDATION_COMPARISON_RESULTS_TABS,
    RESULT_STATUS
  } from '@/constants'
  import ValidationExplanation from '@/components/validations/ValidationExplanation.vue'
  import { useRouteParams } from '@/composables/useRouteParams'
  import notify from '@/utils/notify'
  import { poll } from '@/api/polling'
  import { getTestCaseValidationExplanation, requestTestCaseValidationExplanation } from '@/api'
  import TestCaseValidationResultsStatusItem from './TestCaseValidationResultsStatusItem.vue'

  export default {
    name: 'TestCaseValidationComparisonTable',
    components: { TestCaseValidationResultsStatusItem, ValidationExplanation },
    props: {
      tab: {
        type: String,
        required: false,
        default: 'OVERVIEW'
      },
      comparisonResults: {
        type: Array,
        required: true
      },
      loading: {
        type: Boolean,
        required: true
      }
    },
    setup() {
      const { routeParams } = useRouteParams()
      const { executePoll } = poll()

      const columns = ref(validationsComparisonResultsColumns)
      const tabs = ref(VALIDATION_COMPARISON_RESULTS_TABS)

      const validationResultExplanation = ref('')
      const expandedValidationResultId = ref('')
      const expandedValidationResultKey = ref('')
      const isWhyExplanationLoading = ref(false)

      const planningPeriodLabels = ref({ main: 'Main', compared: 'Compared' })

      const formatPlanningPeriod = ({ from, to }) => {
        const fromValue = removePrefixFromPlanningPeriod(from)
        const toValue = removePrefixFromPlanningPeriod(to)
        return fromValue && toValue ? `${ fromValue } - ${ toValue }` : ''
      }

      const formattedStatus = row => [
        { name: 'status', value: RESULT_STATUS[row.mainTestCaseValidationStatus], key: 'mainValidationId' },
        { name: 'status', value: RESULT_STATUS[row.comparedTestCaseValidationStatus], key: 'comparedValidationId' }]

      const getValidationResultExplanation = async ({ resultId = '', resultKey = '' } = {}) => {
        try {
          isWhyExplanationLoading.value = true
          const validationId = routeParams.value[resultKey]

          const { whyExplanationResponse } = await executePoll({
            correlationIdEndpoint: {
              getCorrelationId: requestTestCaseValidationExplanation,
              params: { validationId, validationResultId: resultId }
            },
            asyncEndpoint: getTestCaseValidationExplanation
          })

          validationResultExplanation.value = whyExplanationResponse
          expandedValidationResultId.value = resultId
          expandedValidationResultKey.value = resultKey
        }
        catch (e) {
          notify({
            title: `Error ${ e.status || '' }`,
            content: 'Fetch test case validation explanation',
            type: 'negative'
          })
        }
        finally {
          isWhyExplanationLoading.value = false
        }
      }

      return {
        columns,
        isWhyExplanationLoading,
        formatPlanningPeriod,
        expandedValidationResultId,
        planningPeriodLabels,
        validationResultExplanation,
        tabs,
        formattedStatus,
        getValidationResultExplanation,
        expandedValidationResultKey
      }
    }

  }
</script>

<style lang="scss" scoped>
  .validation-comparison-body ::v-deep(.q-panel) {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .validation-comparison-body ::v-deep(.q-tab-panel) {
    flex: 1;
    display: flex;
    overflow: hidden;
  }

  .validation-comparison-body ::v-deep(.q-tab__label) {
    font-size: 14px;
  }

  .validation-comparison-body {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;

    .q-tab-panels {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    &__tabs {
      background-color: $mi-white;
      padding: 0 24px;
    }

    &__tab_button {
      font-family: "Man Europe Condensed", sans-serif;
      font-size: 16px;
      font-weight: 700;
      line-height: 21px;
      text-align: left;
      padding: 12px;
    }

    &__tab_panel {
      background-color: $mi-anthracite-50;
      padding: 0;
    }
  }

  /* stylelint-disable declaration-no-important */
  .mi-table-wrapper ::v-deep(.q-table__bottom--nodata) {
    width: 100%;
    align-items: center;

    .no-data-message {
      font-size: 14px;
      color: $mi-anthracite-800;
      justify-items: center;

      &__text {
        font-family: $mi-typography-font-family-condensed;
        font-weight: 700;
        margin-bottom: 8px;
      }

      &__sub-text {
        font-family: $mi-typography-font-family;
        font-weight: 400;
      }
    }
  }

  .status-column {
    padding-top: 8px;
    padding-bottom: 10px;
  }
</style>

<template>
  <div class="validation-comparison">
    <div class="header-comparison-wrapper">
      <validation-results-header
        :is-comparison-page="true"
      ></validation-results-header>
    </div>
    <mi-tabs v-model="activeTab" class="validation-comparison-body__tabs">
      <mi-tab
        v-for="(tabValue) in tabs"
        :key="tabValue.key"
        :name="tabValue.key"
        :label="tabValue.label"
        product-model-change
        :badge="tabValue.key === tabs.ONLY_DIFFERENCES.key? differencesComparisonResults.length: ''"
        class="q-mb-md"
      ></mi-tab>
    </mi-tabs>
    <mi-tab-panels v-model="activeTab" :animated="false" keep-alive>
      <mi-tab-panel :name="tabs.OVERVIEW.key" class="validation-comparison__tab_panel">
        <test-case-validation-comparison-table
          :loading="areComparisonResultsLoading"
          :comparison-results="comparisonResults"
        >
        </test-case-validation-comparison-table>
      </mi-tab-panel>
      <mi-tab-panel :name="tabs.ONLY_DIFFERENCES.key" class="validation-comparison__tab_panel">
        <test-case-validation-comparison-table
          :tab="tabs.ONLY_DIFFERENCES.key"
          :loading="areComparisonResultsLoading"
          :comparison-results="differencesComparisonResults"
        >
        </test-case-validation-comparison-table>
      </mi-tab-panel>
    </mi-tab-panels>
  </div>
</template>

<script>
  import { onMounted, ref } from 'vue'
  import {
    RESULT_STATUS as resultStatus,
    VALIDATION_COMPARISON_RESULTS_TABS as resultTabs
  } from '@/constants'
  import ValidationResultsHeader
    from '@/components/validations/test-cases/validation-results/ValidationResultsHeader.vue'
  import { getTestCasesComparison } from '@/api'
  import { useRouteParams } from '@/composables/useRouteParams'
  import TestCaseValidationComparisonTable from './TestCaseValidationComparisonTable.vue'

  export default {
    name: 'TestCaseValidationComparison',
    components: {
      ValidationResultsHeader,
      TestCaseValidationComparisonTable
    },
    setup() {
      const { routeParams } = useRouteParams()

      const tabs = ref(resultTabs)
      const activeTab = ref(resultTabs.OVERVIEW.key)

      const comparisonResults = ref([])
      const differencesComparisonResults = ref([])
      const areComparisonResultsLoading = ref(false)

      const fetchComparisonResults = async () => {
        try {
          areComparisonResultsLoading.value = true
          const response = await getTestCasesComparison({
            main: routeParams.value.mainValidationId,
            compared: routeParams.value.comparedValidationId
          })

          comparisonResults.value = response.comparisonResults
          differencesComparisonResults.value = response.comparisonResults.filter(
            value => value.comparedTestCaseValidationStatus !== value.mainTestCaseValidationStatus
          )
        }
        catch (error) {
          console.error(error)
        }
        finally {
          areComparisonResultsLoading.value = false
        }
      }

      onMounted(async () => {
        await fetchComparisonResults()
      })

      return {
        resultStatus,
        tabs,
        activeTab,
        differencesComparisonResults,
        comparisonResults,
        areComparisonResultsLoading
      }
    }
  }
</script>

<style lang="scss" scoped>
  .validation-comparison ::v-deep(.q-panel) {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .validation-comparison ::v-deep(.q-tab-panel) {
    flex: 1;
    display: flex;
    overflow: hidden;
  }

  .validation-comparison ::v-deep(.q-tab__label) {
    font-size: 14px;
  }

  .validation-comparison {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;

    .q-tab-panels {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    &__tab_panel {
      background-color: $mi-anthracite-50;
      padding: 0;
    }
  }

  .validation-comparison-body__tabs {
    padding: 0 24px;
  }

  .header-comparison-wrapper {
    padding: 12px 24px 0;
  }
</style>
